import DashboardLayout from "@/pages/layouts/DashboardLayout";

let jobRoutes = {
  path: '/jobs',
  component: DashboardLayout,
  redirect: '/jobs/list',
  children: [
    {
      path: 'list',
      name: 'Job List',
      component: () => import(/* webpackChunkName: "jobs" */  '@/pages/jobRequests/jobs/JobList'),
      meta: {
        permissionsCodes: ['jobs/index'],
      }
    },
    {
      path: 'create',
      name: 'Add Job',
      component: () => import(/* webpackChunkName: "jobs" */  '@/pages/jobRequests/jobs/JobForm'),
      meta: {
        permissionsCodes: ['jobs/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Job',
      component: () => import(/* webpackChunkName: "jobs" */  '@/pages/jobRequests/jobs/JobForm'),
      meta: {
        permissionsCodes: ['jobs/update'],
      }
    }
  ]
}

export default jobRoutes;
