import DashboardLayout from "@/pages/layouts/DashboardLayout";

let avatarRoutes = {
  path: '/avatars',
  component: DashboardLayout,
  redirect: '/avatars/list',
  children: [
    {
      path: 'list',
      name: 'Avatar List',
      component: () => import(/* webpackChunkName: "avatars" */  '@/pages/avatars/AvatarList'),
      meta: {
        permissionsCodes: ['avatars/index' ],
      }
    },
    {
      path: 'create',
      name: 'Add Avatar',
      component: () => import(/* webpackChunkName: "avatars" */  '@/pages/avatars/AvatarForm'),
      meta: {
        permissionsCodes: ['avatars/create'],
      }
    },
    {
      path: 'edit/:id',
      name: 'Edit Avatar',
      component: () => import(/* webpackChunkName: "avatars" */  '@/pages/avatars/AvatarForm'),
      meta: {
        permissionsCodes: ['avatars/update' ],
      }
    }
  ]
}

export default avatarRoutes;
